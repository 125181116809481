<template>
  <section>
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/machinery"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/machinery"><a>Maszyny</a></router-link>
          <router-link tag="li" to="/records/machinery/add" class="is-active"><a>Dodaj nową maszyne</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->

    <MachineryForm />
  </section>
</template>

<script>
  import MachineryForm from '@/views/Records/Machinery/MachineryForm'
  export default {
    name: 'MachineryAdd',
    components: {
      MachineryForm
    }   
  }
</script>

<style lang="scss" scoped>

</style>